import { Auth } from "aws-amplify";
import { millisecondsToSeconds } from "date-fns";
import { set } from "lodash";
import {
  AuthApi,
  Configuration,
  ExtractionApi,
  IngestionApi,
  ListExtractionsRequest,
  ListIngestionsRequest,
  ListLogsRequest,
  ListTopicRecordsFreqEnum,
  LogApi,
  LogsLogIdExtractionEstimationsTopics,
  Middleware,
  MiscApi,
  RequestContext,
  SampleFreq,
  TopicApi,
  UserApi,
} from "racer-openapi-ts-sdk";
import { DeepReadonly } from "ts-essentials";
import {
  BaseListOptions,
  BaseSearchOptions,
  Credentials,
  Extraction,
  ExtractionSearchOptions,
  ExtractionTopic,
  ExtractionUpdates,
  Label,
  ListLogTopicsOptions,
  ListTopicRecordsOptions,
  ListTopicsOptions,
  Log,
  LogMetadata,
  LogSearchOptions,
  NewExtraction,
  NewFeedback,
  NewLabel,
  Tokens,
  Topic,
  UseIngestionsOptions,
  User,
  UserChallengeResponse,
  UserConfig,
} from "./queries";
import { msToS } from "./utils";

interface ApiClients {
  authApi: AuthApi;
  ingestionApi: IngestionApi;
  extractionApi: ExtractionApi;
  logApi: LogApi;
  miscApi: MiscApi;
  topicApi: TopicApi;
  userApi: UserApi;
}

export class AmplifyMiddleware implements Middleware {
  async pre({ init }: RequestContext): Promise<void> {
    const session = await Auth.currentSession();

    set(init, "headers.Authorization", session.getIdToken().getJwtToken());
  }
}

export class ApiClient {
  clients: ApiClients;

  constructor(config?: Configuration) {
    this.clients = {
      authApi: new AuthApi(config),
      ingestionApi: new IngestionApi(config),
      extractionApi: new ExtractionApi(config),
      logApi: new LogApi(config),
      miscApi: new MiscApi(config),
      topicApi: new TopicApi(config),
      userApi: new UserApi(config),
    };
  }

  static #baseOptsToApi(
    opts: BaseSearchOptions | BaseListOptions,
    fieldMap?: Map<string, string>
  ) {
    let limit: number | undefined = undefined;
    let offset: number | undefined = undefined;

    if ("limit" in opts || "offset" in opts) {
      limit = opts.limit;
      offset = opts.offset;
    }

    if ("page" in opts || "pageSize" in opts) {
      limit = opts.pageSize;
      // In all cases except when both pageSize and page are supplied, the
      // offset will be 0
      offset = (opts.pageSize ?? 0) * (opts.page ?? 0);
    }

    return {
      sort: opts.sort,
      order:
        opts.field !== undefined
          ? fieldMap?.get(opts.field) ?? opts.field
          : undefined,
      limit,
      offset,
    };
  }

  static #extractionTopicsToApi(
    topics: DeepReadonly<ExtractionTopic[]>
  ): LogsLogIdExtractionEstimationsTopics[] {
    const apiTopics: LogsLogIdExtractionEstimationsTopics[] = [];

    topics.forEach((extractionTopic) => {
      extractionTopic.ranges.forEach((range) => {
        const newTopic: LogsLogIdExtractionEstimationsTopics = {
          topic_id: extractionTopic.topic.id,
          start_time: millisecondsToSeconds(range.startTimeMs),
          end_time: millisecondsToSeconds(range.endTimeMs),
          freq: null,
        };

        apiTopics.push(newTopic);
      });
    });

    return apiTopics;
  }

  listLogs = (options: LogSearchOptions) => {
    const fieldMap = new Map([["startDate", "start_time"]]);

    const req: ListLogsRequest = {
      ...ApiClient.#baseOptsToApi(options, fieldMap),
      name: options.name || undefined,
      name_like: options.nameLike || undefined,
      status: options.status || undefined,
      group_id: options.groupId || undefined,
    };

    return this.clients.logApi.listLogs(req);
  };

  getLog = (logId: Log["id"]) => {
    return this.clients.logApi.getLog({ logId });
  };

  updateLog = (logId: Log["id"], updates: Partial<LogMetadata>) => {
    return this.clients.logApi.updateLog({
      logId,
      UpdateLogRequest: {
        name: updates.name,
        note: updates.note,
        operator: updates.operator,
        vehicle: updates.vehicle,
        test_id: updates.testId,
        comments: updates.comments,
        sensors_online: updates.sensorsOnline,
        software_config: updates.softwareConfig,
      },
    });
  };

  listLogLabels = (logId: Log["id"]) => {
    return this.clients.logApi.listLogLabels({
      logId,
      limit: 10_000,
    });
  };

  createLogLabel = (logId: Log["id"], label: NewLabel) => {
    const start_time = millisecondsToSeconds(label.startTimeMs);

    const end_time =
      label.endTimeMs === null ? null : millisecondsToSeconds(label.endTimeMs);

    const tag = label.tag === null ? null : label.tag.name;
    const tag_type = label.tag === null ? null : label.tag.type;

    const content = label.description;

    return this.clients.logApi.createLogLabel({
      logId,
      CreateLogLabelRequest: {
        start_time,
        end_time,
        tag,
        tag_type,
        content,
      },
    });
  };

  deleteLogLabel = (logId: Log["id"], labelId: Label["id"]) => {
    return this.clients.logApi.deleteLabel({ logId, labelId });
  };

  listTags = () => {
    return this.clients.miscApi.listTags({ limit: 10_000, order: "value" });
  };

  getMotd = () => {
    return this.clients.miscApi.getMOTD();
  };

  listIngestions = (options: UseIngestionsOptions) => {
    const fieldMap = new Map([["createdAt", "created_at"]]);

    const req: ListIngestionsRequest = {
      ...ApiClient.#baseOptsToApi(options, fieldMap),
      log_id: options.logId || undefined,
      status: options.status || undefined,
    };

    return this.clients.ingestionApi.listIngestions(req);
  };

  listExtractions = (options: ExtractionSearchOptions) => {
    const fieldMap = new Map([["createdAt", "created_at"]]);

    const req: ListExtractionsRequest = {
      ...ApiClient.#baseOptsToApi(options, fieldMap),
      log_id: options.logId || undefined,
      name: options.name || undefined,
      name_like: options.nameLike || undefined,
      status: options.status || undefined,
      created_by: options.createdBy || undefined,
    };

    return this.clients.extractionApi.listExtractions(req);
  };

  getExtraction = (logId: Log["id"], extractionId: Extraction["id"]) => {
    return this.clients.logApi.getLogExtraction({
      logId,
      extractionId,
    });
  };

  listExtractionTopics = (logId: Log["id"], extractionId: Extraction["id"]) => {
    return this.clients.logApi.listLogExtractionTopics({
      logId,
      extractionId,
      limit: 10_000,
    });
  };

  listExtractionFiles = (logId: Log["id"], extractionId: Extraction["id"]) => {
    return this.clients.logApi.listLogExtractionFiles({
      logId,
      extractionId,
      order: "id",
      limit: 1_000,
    });
  };

  estimateExtraction = (logId: Log["id"], topics: ExtractionTopic[]) => {
    return this.clients.logApi.createExtractionEstimation({
      logId,
      CreateExtractionEstimationRequest: {
        topics: ApiClient.#extractionTopicsToApi(topics),
      },
    });
  };

  createExtraction = (
    logId: Log["id"],
    newExtraction: DeepReadonly<NewExtraction>
  ) => {
    return this.clients.logApi.createLogExtraction({
      logId,
      CreateExtractionRequest: {
        name: newExtraction.name,
        topics: ApiClient.#extractionTopicsToApi(newExtraction.topics),
      },
    });
  };

  updateExtraction = (
    logId: Log["id"],
    extractionId: Extraction["id"],
    updates: ExtractionUpdates
  ) => {
    return this.clients.logApi.updateLogExtraction({
      logId,
      extractionId,
      UpdateExtractionRequest: updates,
    });
  };

  getUserAuth = () => {
    return this.clients.authApi.getAuth();
  };

  refreshTokens = (refreshToken: Tokens["refreshToken"]) => {
    return this.clients.authApi.refreshToken({
      RefreshTokenRequest: {
        refresh_token: refreshToken,
      },
    });
  };

  signUp = (email: Credentials["email"]) => {
    return this.clients.authApi.signUp({
      SignUpRequest: {
        username: email,
      },
    });
  };

  signIn = (credentials: Credentials) => {
    return this.clients.authApi.signIn({
      SignInRequest: {
        username: credentials.email,
        password: credentials.password,
      },
    });
  };

  respondToChallenge = ({
    challenge,
    challengeArgs,
  }: UserChallengeResponse) => {
    return this.clients.authApi.respondToNewPasswordChallenge({
      RespondToNewPasswordChallengeRequest: {
        session: challenge.session,
        username: challengeArgs.email,
        new_password: challengeArgs.newPassword,
      },
    });
  };

  signOut = (refreshToken: Tokens["refreshToken"]) => {
    return this.clients.authApi.signOut({
      SignOutRequest: {
        refresh_token: refreshToken,
      },
    });
  };

  getUser = () => {
    return this.clients.userApi.getAuthedUser();
  };

  listGroups = () => {
    return this.clients.userApi.listGroups({
      sort: "asc",
      order: "name",
      limit: 100,
    });
  };

  listApiKeys = () => {
    return this.clients.userApi.listAuthedUserAPIKeys({ limit: 100 });
  };

  updateUserConfig = (user: User, updatedConfig: Partial<UserConfig>) => {
    // Don't overwrite existing config values since a user may be storing
    // data in it from other apps
    const mergedConfig = {
      ...user.config,
      ...updatedConfig,
    };

    return this.clients.userApi.updateAuthedUser({
      UpdateAuthedUserRequest: {
        config: mergedConfig,
      },
    });
  };

  listTopics = (options: ListTopicsOptions) => {
    return this.clients.topicApi.listTopics({
      ...ApiClient.#baseOptsToApi(options),
      name: options.name ?? undefined,
    });
  };

  listLogTopics = (searchOpts: ListLogTopicsOptions) => {
    return this.clients.logApi.listLogTopics({
      ...ApiClient.#baseOptsToApi(searchOpts),
      logId: searchOpts.logId,
    });
  };

  getLogTopic = (logId: Log["id"], topicId: Topic["id"]) => {
    return this.clients.logApi.getTopic({
      logId,
      topicId,
    });
  };

  listTopicRecords = (options: ListTopicRecordsOptions) => {
    // This is pretty nasty. Not sure how to get the generator to *not*
    // generate an entirely new enum that's the same as the existing
    // SampleFreq enum. Instead, since the only valid sample frequencies
    // users can supply, aside from undefined, are 'second' and 'decisecond'
    // a ternary will work here to convert SampleFreq to the
    // ListTopicRecordsFreqEnum value.
    let freq: ListTopicRecordsFreqEnum | undefined;
    if (options.freq !== undefined) {
      freq =
        options.freq === SampleFreq.Second
          ? ListTopicRecordsFreqEnum.Second
          : ListTopicRecordsFreqEnum.Decisecond;
    }

    // Not a fan of explicitly assigning undefined which I would have to
    // do if I wanted to use a ternary and assign the start and end times
    // in the object literal below.
    let start_time: number | undefined;
    if (options.startTimeMs !== undefined) {
      start_time = msToS(options.startTimeMs);
    }

    let end_time: number | undefined;
    if (options.endTimeMs !== undefined) {
      end_time = msToS(options.endTimeMs);
    }

    const apiOpts = {
      logId: options.logId,
      topicId: options.topicId,
      ...ApiClient.#baseOptsToApi(options),
      start_time,
      end_time,
      freq,
      include_image: options.includeImage,
    };

    return this.clients.logApi.listTopicRecords(apiOpts);
  };

  createFeedback = (feedback: NewFeedback) => {
    return this.clients.miscApi.createFeedback({
      CreateFeedbackRequest: {
        info: feedback.info,
        feedback_type: feedback.type,
      },
    });
  };
}
