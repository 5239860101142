import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import config from "../config";

export default function SignIn() {
  return (
    <>
      <Helmet>
        <title>Sign In &bull; {config.datastoreInstanceName} DataStore</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          "& .MuiCard-root": {
            m: "auto",
            width: 1,
          },
        }}
        maxWidth="sm"
      >
        <Card elevation={4}>
          <CardContent>
            <Typography variant="h5" component="h1" gutterBottom>
              Sign In to {config.datastoreInstanceName} DataStore
            </Typography>
            <Typography paragraph>
              Below, you can sign in to your {config.datastoreInstanceName}{" "}
              DataStore account or create a new account if you haven't made one
              yet.
            </Typography>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => Auth.federatedSignIn()}
            >
              Sign In
            </Button>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
