import invariant from "invariant";

const config = {
  datastoreInstanceName: required("REACT_APP_DATASTORE_INSTANCE_NAME"),
  navbarStateStorageKey: "sidebar-state",
  motdStorageKey: "last-motd",
  useHashRouter: process.env.REACT_APP_USE_HASH_ROUTER === "true",
  api: {
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  auth: {
    userPoolRegion: required("REACT_APP_USER_POOL_REGION"),
    userPoolId: required("REACT_APP_USER_POOL_ID"),
    userPoolWebClientId: required("REACT_APP_USER_POOL_WEB_CLIENT_ID"),
    domain: required("REACT_APP_OAUTH_DOMAIN"),
    scope: required("REACT_APP_OAUTH_SCOPES").split(","),
  },
  setAuthCookie:
    // It's common to run code locally against the prod endpoints so
    // the auth cookie should **only** be set if we're *building* for prod
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_SET_AUTH_COOKIE === "true",
  authCookieName: "token",
  home: {
    guide: {
      href: "https://guide.racer-datastore.com/",
    },
    api: {
      href: "https://docs.racer-datastore.com/",
    },
    icd: {
      href: "https://icd.racer-datastore.com/",
    },
    fleet: {
      href: "https://icd.racer-datastore.com/assets/documentation",
    },
  },
};

export default config;

function required(envVarName: string): string {
  const envVar = process.env[envVarName];

  invariant(
    envVar !== undefined,
    `Env var "${envVarName}" is required but wasn't set`
  );

  return envVar;
}
