import React from "react";
import { Fullscreen, Root, Scheme } from "@mui-treasury/layout";
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { secondsToMilliseconds } from "date-fns";
import { enableAllPlugins } from "immer";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useLocalStorage } from "react-use";
import { QueryParamProvider } from "use-query-params";
import AuthenticatedRoute from "../components/AuthenticatedRoute";
import DefaultErrorFallback from "../components/DefaultErrorFallback";
import GlobalNavigation from "../components/GlobalNavigation";
import config from "../config";
import "../css/styles.css";
import AuthProvider from "../providers/AuthProvider";
import FeaturesProvider, { AppFeatures } from "../providers/Features";
import ExtractionDetails from "./ExtractionDetails";
import ExtractionSearch from "./ExtractionSearch";
import IngestionSearch from "./IngestionSearch";
import Log from "./Log";
import LogListView from "./LogSearch";
import Profile from "./Profile";
import SignIn from "./SignIn";
import * as paths from "./paths";
import { LayoutOptions } from "./types";

enableAllPlugins();

const Router: React.ComponentType = config.useHashRouter
  ? HashRouter
  : BrowserRouter;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: secondsToMilliseconds(5),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // Default staleTime of Infinity for all queries. We deal with too
      // much data to be refetching willy-nilly and I'd prefer to control
      // when refetching happens anyways
      staleTime: Infinity,
    },
  },
});

const commonLayoutConfig: Partial<Scheme> = {
  header: {
    config: {
      xs: {
        position: "relative",
        height: 48,
        clipped: {
          rightEdgeSidebar: true,
        },
      },
    },
  },
  leftEdgeSidebar: {
    config: {
      xs: {
        variant: "temporary",
        width: 220,
      },
      md: {
        variant: "persistent",
        persistentBehavior: "fit",
        width: 220,
      },
    },
  },
};

const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiTooltip: {
        defaultProps: {
          enterTouchDelay: 100,
        },
      },
    },
  })
);

export interface AppProps {
  homepage?: React.ReactNode;
  features?: AppFeatures;
}

export default function App({ homepage, features }: AppProps) {
  const [initialOpen] = useLocalStorage(config.navbarStateStorageKey, true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  function renderRoute(
    route: React.ReactNode,
    { scheme, initialState }: LayoutOptions = {}
  ) {
    return (
      // @ts-ignore
      <Fullscreen>
        <Root
          scheme={{
            ...scheme,
            ...commonLayoutConfig,
          }}
          initialState={{
            ...initialState,
            leftEdgeSidebar: {
              open: isMobile ? false : initialOpen,
            },
          }}
        >
          <GlobalNavigation linkToHomepage={homepage !== undefined} />
          {route}
        </Root>
      </Fullscreen>
    );
  }

  return (
    <FeaturesProvider features={features}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
          <Helmet>
            <title>{config.datastoreInstanceName} DataStore</title>
          </Helmet>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ConfirmProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <SnackbarProvider maxSnack={1}>
                      <Switch>
                        <AuthenticatedRoute path={paths.extractionDetails}>
                          {renderRoute(<ExtractionDetails />)}
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={paths.ingestionSearch}>
                          {renderRoute(
                            <IngestionSearch />,
                            IngestionSearch.layoutOptions
                          )}
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={paths.extractionSearch}>
                          {renderRoute(
                            <ExtractionSearch />,
                            ExtractionSearch.layoutOptions
                          )}
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={paths.log}>
                          {renderRoute(<Log />, Log.layoutOptions)}
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={paths.logSearch}>
                          {renderRoute(
                            <LogListView />,
                            LogListView.layoutOptions
                          )}
                        </AuthenticatedRoute>
                        <AuthenticatedRoute path={paths.profile}>
                          {renderRoute(<Profile />)}
                        </AuthenticatedRoute>
                        <Route path={paths.signIn}>
                          <SignIn />
                        </Route>
                        {homepage !== undefined ? (
                          <AuthenticatedRoute path={paths.home}>
                            {renderRoute(homepage)}
                          </AuthenticatedRoute>
                        ) : (
                          <Redirect
                            from={paths.home}
                            to={paths.toLogSearch()}
                          />
                        )}
                      </Switch>
                    </SnackbarProvider>
                  </QueryParamProvider>
                </Router>
              </ConfirmProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </FeaturesProvider>
  );
}
