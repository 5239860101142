import React, { PropsWithChildren, useEffect, useState } from "react";
import type { HubCallback } from "@aws-amplify/core";
import { Amplify, Auth, Hub } from "aws-amplify";
import Cookie from "js-cookie";
import { Configuration, ConfigurationParameters } from "racer-openapi-ts-sdk";
import { AmplifyMiddleware, ApiClient } from "../apiClient";
import config from "../config";
import { createSafeContext } from "../contexts";
import { ApiContext } from "./ApiProvider";

Amplify.configure({
  Auth: {
    region: config.auth.userPoolRegion,
    userPoolId: config.auth.userPoolId,
    userPoolWebClientId: config.auth.userPoolWebClientId,
    oauth: {
      domain: config.auth.domain,
      scope: config.auth.scope,
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
  },
});

export type AuthProviderProps = PropsWithChildren<{}>;

export type AuthState = "authenticated" | "unauthenticated" | "expired";

export const [useAuth, AuthContext] = createSafeContext<AuthState>("Auth");

const baseConfigParameters: ConfigurationParameters = {
  basePath: config.api.baseUrl,
};

const unauthenticatedClient = new ApiClient(
  new Configuration(baseConfigParameters)
);
const authenticatedClient = new ApiClient(
  new Configuration({
    ...baseConfigParameters,
    middleware: [new AmplifyMiddleware()],
  })
);

const apiContextValue = { unauthenticatedClient, authenticatedClient };

export default function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = useState<AuthState | null>(null);

  useEffect(function setInitialAuthState() {
    (async function checkForAuthenticatedUser() {
      try {
        await Auth.currentAuthenticatedUser();
        setAuthState("authenticated");
      } catch {
        setAuthState("unauthenticated");
      }
    })();
  }, []);

  useEffect(function respondToAuthEvents() {
    const handleAuthEvent: HubCallback = function handleAuthEvent({
      payload: { event },
    }) {
      if (event === "tokenRefresh_failure") {
        setAuthState("expired");
      }
    };

    Hub.listen("auth", handleAuthEvent);

    return () => {
      Hub.remove("auth", handleAuthEvent);
    };
  }, []);

  if (authState === null) {
    return null;
  } else {
    return (
      <AuthContext.Provider value={authState}>
        <ApiContext.Provider value={apiContextValue}>
          {children}
        </ApiContext.Provider>
      </AuthContext.Provider>
    );
  }
}

Hub.listen("auth", function manageAuthCookie({ payload: { event } }) {
  if (event === "cognitoHostedUI" || event === "tokenRefresh") {
    setAuthCookie();
  }

  if (event === "oAuthSignOut" || event === "tokenRefresh_failure") {
    removeAuthCookie();
  }
});

export function setAuthCookie(): void {
  // Set auth cookie for ICD docs site access if making a production
  // build and env var is properly set
  if (config.setAuthCookie) {
    Auth.currentSession().then((session) => {
      Cookie.set(config.authCookieName, session.getIdToken().getJwtToken(), {
        secure: true,
        domain: window.location.hostname,
        sameSite: "lax",
      });
    });
  }
}

export function removeAuthCookie(): void {
  // Remove auth cookie for ICD docs site access if making a production
  // build and env var is properly set
  if (config.setAuthCookie) {
    Cookie.remove(config.authCookieName, { domain: window.location.hostname });
  }
}
