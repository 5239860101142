import { ReactNode, useEffect, useRef, useState } from "react";
import {
  EdgeSidebar,
  SidebarContent,
  useLayoutCtx,
} from "@mui-treasury/layout";
import {
  AccountCircle,
  CloudDownload,
  CloudUpload,
  Feedback,
  Home,
  Logout,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { FileCloud } from "mdi-material-ui";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";
import { useLocalStorage } from "react-use";
import config from "../config";
import * as paths from "../views/paths";
import { useActivePath } from "../views/paths";
import FeedbackDialog from "./FeedbackDialog";

export interface GlobalNavigationProps {
  linkToHomepage?: boolean;
}

export default function GlobalNavigation({
  linkToHomepage = true,
}: GlobalNavigationProps) {
  const [, setLocalStorageOpen] = useLocalStorage<boolean>(
    config.navbarStateStorageKey
  );
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const location = useLocation();

  const isActive = useActivePath();

  const { state, setOpen } = useLayoutCtx();
  // MUI Treasury doesn't memoize their setters
  const setOpenRef = useRef(setOpen);

  const isOpen = state.leftEdgeSidebar?.open ?? true;

  useEffect(
    function persistNavbarCollapsedState() {
      setLocalStorageOpen(isOpen);
    },
    [setLocalStorageOpen, isOpen]
  );

  useEffect(
    function closeModalDrawerOnLocationChange() {
      if (isMobile) {
        setOpenRef.current("leftEdgeSidebar", false);
      }
    },
    [isMobile, location]
  );

  return (
    <EdgeSidebar
      anchor="left"
      // MUI Treasury overwrites the modal z-index depending on overall
      // layout scheme, so I need to reset it myself so it isn't clipped
      // by the app bar
      sx={{ "&.MuiDrawer-modal": { zIndex: "modal" } }}
    >
      <SidebarContent sx={{ overflowX: "hidden" }}>
        <List
          component="nav"
          sx={{
            py: 0,
            height: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {linkToHomepage &&
            renderLink("Home", <Home />, isActive(paths.home), paths.home)}
          {renderLink(
            "Ingestions",
            <CloudUpload />,
            isActive(paths.ingestionSearch),
            paths.ingestionSearch
          )}
          {renderLink(
            "Logs",
            <FileCloud />,
            isActive([paths.logSearch, paths.log]),
            paths.toLogSearch({ status: "processed" })
          )}
          {renderLink(
            "Extractions",
            <CloudDownload />,
            isActive([paths.extractionSearch, paths.extractionDetails]),
            paths.extractionSearch
          )}
          <ListItem
            sx={{ px: 0, pt: 8, mt: "auto" }}
            component="div"
            disablePadding
          >
            <ListItemButton onClick={() => setShowFeedbackDialog(true)}>
              <ListItemIcon>
                <Feedback />
              </ListItemIcon>
              <ListItemText>Feedback</ListItemText>
            </ListItemButton>
          </ListItem>
          {renderLink(
            "Profile",
            <AccountCircle />,
            isActive(paths.profile),
            paths.profile
          )}
          <ListItem component="div" disablePadding>
            <ListItemButton onClick={() => Auth.signOut()}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText>Sign Out</ListItemText>
            </ListItemButton>
          </ListItem>
          <FeedbackDialog
            onModalClose={() => setShowFeedbackDialog(false)}
            open={showFeedbackDialog}
          >
            Help us make the DataStore better! If you notice any bugs or have an
            idea for a new feature, submit it here.
          </FeedbackDialog>
        </List>
      </SidebarContent>
    </EdgeSidebar>
  );
}

function renderLink(
  text: ReactNode,
  icon: JSX.Element,
  selected: boolean,
  to: RouterLinkProps["to"]
) {
  return (
    <ListItem component="div" disablePadding>
      <ListItemButton
        selected={selected}
        component={RouterLink}
        to={to}
        sx={{
          "&.Mui-selected": {
            color: "primary.main",
            fontWeight: "bold",
            "& .MuiSvgIcon-root": {
              color: "primary.main",
            },
          },
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            noWrap: true,
            color: "inherit",
            fontWeight: "inherit",
          }}
        >
          {text}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
