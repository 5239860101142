import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useQueryClient } from "react-query";
import { Redirect, Route } from "react-router-dom";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import { groupKeys, tagKeys, userKeys } from "../queries";
import * as paths from "../views/paths";

export default function AuthenticatedRoute({ children, ...routeProps }) {
  const authState = useAuth();

  if (authState === "expired") {
    return (
      <>
        <Route {...routeProps}>{children}</Route>
        <Dialog open fullWidth aria-labelledby="sign-in-dialog-title">
          <DialogTitle id="sign-in-dialog-title">Session Expired</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your session has expired. You need to sign in again to continue
              using the DataStore.
            </DialogContentText>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => Auth.federatedSignIn()}
            >
              Sign In
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  } else if (authState === "authenticated") {
    return (
      <Route {...routeProps}>
        <Prefetch />
        {children}
      </Route>
    );
  } else {
    return <Redirect to={paths.signIn} />;
  }
}

function Prefetch() {
  const { authenticatedClient } = useApi();

  const queryClient = useQueryClient();

  useEffect(
    function prefetchCommonQueries() {
      queryClient.prefetchQuery(
        groupKeys.list(),
        authenticatedClient.listGroups
      );

      queryClient.prefetchQuery(userKeys.detail(), authenticatedClient.getUser);

      queryClient.prefetchQuery(tagKeys.all, authenticatedClient.listTags);
    },
    [queryClient, authenticatedClient]
  );

  return null;
}
